@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
modBtn
*/

.modBtn {
  background-color: transparent;
  border: 1px solid;
  border-radius: 36px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 16px;
  margin: 0 auto;
  width: 260px;

  @include mixin.sp {
    width: calc(100% - 30px);
  }

  &.-sm {
    font-size: 14px;
    padding: 14px 16px;
    width: 215px;
  }

  &.-lg {
    width: 315px;
    max-width: calc(100% - 30px);
  }

  &.btn1 {
    border-color: variables.$COLOR_MAIN;
    color: variables.$COLOR_MAIN;

    &:hover {
      background-color: variables.$COLOR_MAIN;
      color: white;
      opacity: 1;
    }
  }

  &.btn2 {
    background-color: map_get(variables.$COLOR, orange);
    border-color: map_get(variables.$COLOR, orange);
    color: white;
  }

  &.btn3 {
    border-color: map_get(variables.$COLOR, orange);
    color: map_get(variables.$COLOR, orange);
  }

  &.btnSearch,
  &.btnReset {
    font-size: 16px;
    min-height: 60px;
    width: 240px;

    @include mixin.tabletP {
      min-height: auto;
    }
  }

  &.btnSearch {
    background-color: variables.$COLOR_MAIN;
    color: white;
  }

  &.btnReset {
    border-color: variables.$COLOR_TEXT;
    color: variables.$COLOR_TEXT;
  }


  &.-sm,
  &.-lg {

    @include mixin.sp {
      width: calc(100% - 30px);
    }
  }

}

.modAnchor__link {
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  position: relative;

  &:hover {
    opacity: 1;

    .modAnchor__link--icon {
      span {

        &:before,
        &:after {
          height: 100%;
        }
      }
    }

    .modAnchor__link--icon2 {
      transform: scale(1);
      background: variables.$COLOR_MAIN;
      z-index: -1;
    }

    .modAnchor__link--label {
      color: white;
    }
  }

  span {
    line-height: normal;
  }

  &--label {
    color: variables.$COLOR_MAIN;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    padding: 10px 22px;
    min-height: 126px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0.2s;
    z-index: 1;

    @include mixin.tabletP {
      min-height: 100px;
    }

    span {
      font-size: calc(14px + 2 * (100vw - 320px) / 1600);
      font-weight: 400;
      line-height: normal;
    }
  }

  &--icon2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0.2) scaleY(0.5);
    transition: all 0.2s;
    transition-delay: 0.2s;
  }

  &--icon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;

    &:before,
    &:after {
      background: url(#{mixin.set_common_path('anchor_link_img.svg')}) no-repeat center center;
      background-size: auto 100%;
      content: '';
      display: block;
      width: 28px;
      height: 100%;
      position: absolute;
      top: 0;
      transition: all 0.2s;
      transition-delay: 0.2s;

      @include mixin.tabletP {

      }
    }

    &:before {
      left: 0;
      transform: rotate(0deg);
    }

    &:after {
      right: 0;
      transform: rotate(180deg);
    }
  }
}


/* フォーム用ボタン */
/*
#styleguide
.modFormBtn

```
<div class="modFormBtn">
  <input type="submit" value="入力内容確認" class="wpcf7c-btn-confirm">
  <input type="button" value="修正する" class="wpcf7c-btn-back">
  <input type="submit" value="送信" class="wpcf7-submit">
</div>
```
*/
.modFormBtn {
  display: flex;
  align-items: center;
  gap: 20px 15px;
  justify-content: center;
  margin-top: 50px;

  @include mixin.tabletP {
    flex-direction: column;
    gap: 15px 0;
    margin-top: 30px;
  }
}

.wpcf7c-btn-confirm,
.wpcf7c-btn-back,
.wpcf7-submit {
  border: none;
  color: white;
  display: inline-block;
  font-weight: 700;
  padding: 0 15px;
  margin: 0;
  height: 50px;
  width: 320px;
  max-width: 100%;

  &:hover {
    opacity: .7;
  }
}

[type="submit"] {
  background-color: map_get(variables.$COLOR, orange);
}

.wpcf7c-btn-back {
  background-color: map_get(variables.$COLOR, palegray);
  color: #666;
}