@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';

ul.modListJump {
  box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
  gap: 15px;
	margin-bottom: 70px;
	padding: 0;

  @include mixin.tabletP {
    gap: 10px;
  }

	li {
    display: flex;
    width: calc(100% / 4 - 12px);

    @include mixin.tabletL {
      width: calc(100% / 3 - 10px);
    }

    @include mixin.tabletP {
      width: calc(100% / 2 - 5px);
    }
  }

  a {
    border: 1px variables.$COLOR_MAIN solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: variables.$COLOR_MAIN;
    padding: 10px 15px;
    padding-right: 40px;
    text-decoration: none;
    min-height: 60px;
    width: 100%;
    position: relative;

    &::after {
      background: url(#{mixin.set_common_path('icon_dropdown_blue_sm.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 18px;
      height: 9px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.modAnchor {
  display: flex;
  gap: 0 18px;

  @include mixin.tabletP {
    flex-wrap: wrap;
    gap: 14px;
  }

  &__item {
    display: flex;
    align-items: center;
    width: calc(100% / 3 - 6px);

    @include mixin.tabletP {
      width: calc(100% / 2 - 8px);
    }

    &.register {
      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.modFlexList {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 100%;

  @include mixin.tabletP {
    flex-wrap: wrap;
  }
}

.locationList {
  box-sizing: border-box;
  display: flex;
  gap: 0 5px;
  margin-bottom: 10px;

  li {
    &.tag {
      background-color: variables.$COLOR_TEXT;
      color: white;
      font-size: calc(12px + 2 * (100vw - 320px) / 1600);
      padding: 0 5px 1px;
    }
  }
}

.modSpecList {
  margin-bottom: 10px;

  li {
    display: flex;
    font-size: calc(12px + 2 * (100vw - 320px) / 1600);

    &::before {
      display: inline-block;
    }

    &.landarea {
      &::before {
        content: "土地面積：";
      }
    }

    &.access {
      &::before {
        content: "交通：";
      }
    }
  }

}

.modUpdateFav {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.modResultBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @include mixin.tabletP {
    margin-bottom: 10px;
  }

  &::before {
    content: "検索結果：";
    display: inline-block;
  }

  li {
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);

    span {

      &::before,
      &::after {
        display: inline-block;
      }

      &.result {

        &-min {
          &::before {
            content: "件中";
          }
        }

        &-max {
          &::before {
            content: "～";
          }
          &::after {
            content: "件を表示";
          }
        }
      }
    }
  }
}
