@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/* 下層テンプレート */
.modContentsContainer {
  padding-bottom: 50px;
}

.modContents {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 38px;
    margin-bottom: 20px;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 38px;
    margin-bottom: 20px;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: none;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;

    &::before,
    &::after {
      content: "";
      display: table;
      table-layout: fixed;
      clear: both;
    }
  }

  h2 {
    background-color: map_get(variables.$COLOR, gray);
    border-left: 5px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_MAIN;
    padding: 10px;
    padding-left: 20px;
  }

  h3 {
    border-bottom: 3px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_MAIN;
    padding-bottom: 10px;
  }

  h4 {
    color: variables.$COLOR_MAIN;
  }

  h5 {
    color: variables.$COLOR_TEXT;
  }

  p {
    margin-bottom: 40px;
  }

  ul:not(.list_1):not(.list_1 li ul):not(.wp-pagenavi):not(.modListJump) {
    margin-bottom: 50px;
    list-style: none;

    li {
      font-weight: 700;
      padding-left: 20px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      @include mixin.tabletP {
        padding-left: 30px;
      }

      &::before {
        background: map_get(variables.$COLOR, orange);
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol_li;
    padding-left: 0;
    margin-bottom: 50px;

    li {
      font-weight: 700;
      padding-left: 20px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      &::before {
        color: map_get(variables.$COLOR, orange);
        counter-increment: ol_li;
        content: "" counter(ol_li);
        font-family: variables.$FONT_ALPHA;
        font-size: calc(20px + 4 * (100vw - 320px) / 1600);
        font-weight: 700;
        position: absolute;
        line-height: 1;
        top: -1px;
        left: 0;
      }
    }
  }

  .wp-caption,
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  .wp-caption {
    p {
      margin: 0;
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-break: anywhere;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {}

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {}

  iframe {
    width: 100%;
    max-width: 100%;
  }

  a:not(.wp-pagenavi a):not(.modListJump a):not([class^="btn"]):not(.list_1 a) {
    color: #1A52D3;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      transition: .5s ease;
    }

    &:visited {
      color: #1A52D3;
    }
  }
  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt,
  .btn_link {
    display: inline-block;
    padding-right: 30px;
    margin-right: 5px;
  }

  .btn_pdf {
    background: url(#{mixin.set_common_path('bg_pdf.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_doc {
    background: url(#{mixin.set_common_path('bg_doc.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_xls {
    background: url(#{mixin.set_common_path('bg_xls.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_ppt {
    background: url(#{mixin.set_common_path('bg_ppt.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_link {
    background: url(#{mixin.set_common_path('bg_blank.svg')}) no-repeat right center;
    background-size: auto;
    display: inline-block;
  }

  .btn01,
  .btn02 {
    background-color: white;
    border: 1px solid;
    border-radius: 28px;
    padding: 14px 20px;
    text-align: center;
    text-decoration: none;
    transition: .3s ease;
    min-width: 260px;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    &:hover {
      color: white;
      opacity: 1;
      text-decoration: none;
    }
  }

  .btn01 {
    border-color: variables.$COLOR_MAIN;
    color: variables.$COLOR_MAIN;

    &:hover {
      background-color: variables.$COLOR_MAIN;
    }
  }

  .btn02 {
    border-color: map_get(variables.$COLOR, orange);
    color: map_get(variables.$COLOR, orange);

    &:hover {
      background-color: map_get(variables.$COLOR, orange);
    }
  }

  .btn03 {
    color: #1A52D3;
    padding-right: 20px;
    position: relative;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_blue_sm.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      width: 8px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &:hover {
      text-decoration: none;
    }
  }


  table:not(.modkFormTbl) {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;

    th,
    td {
      padding: 30px;

      @include mixin.tabletP {
        padding: 15px;
      }
    }

    tbody {

      th,td {
        border: 1px solid;
        vertical-align: middle;
      }

      th {
        background-color: variables.$COLOR_MAIN;
        border-color: variables.$COLOR_MAIN;
        border-bottom-color: white;
        color: white;
        font-weight: 700;
        width: 30%;
      }

      td {
        background: white;
        border-color: variables.$COLOR_MAIN;
        width: 70%;
      }

      tr {
        &:last-child {
          th {
            border-bottom-color: variables.$COLOR_MAIN;
          }
        }
      }
    }

    .wp-caption {
      p {
        margin: 0;
      }

      img {
        margin: 0 auto;
        padding: 0;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &.tbl_none {
      border: none;
      display: table;
      table-layout: fixed;

      th,
      td {
        background: none;
        border: none;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;

        p {
          font-weight: normal;
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          line-height: normal;
        }
      }

      th {
        color: variables.$COLOR_TEXT;
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      border: none;
      table-layout: fixed;
    }
  }
}

.toggle_panel {
  border-top: 1px #999 solid;
  padding: 34px 0;
  width: 100%;
  max-width: 100%;

  &.last-item {
    border-bottom: 1px #999 solid;
    margin-bottom: 40px;
  }

.accordion_title {
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  padding-right: 60px;
  margin: 0;
  text-align: left;
  position: relative;

  @include mixin.tabletP {
    padding-right: 40px;
  }

  &::before,
  &::after {
    position: absolute;
  }

  &:after {
    background: url(#{mixin.set_common_path('toggle_plus_blue.svg')}) no-repeat center center;
    background-size: 100% auto;
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 0;
    transition-duration: 0.3s;

    @include mixin.tabletP {
      width: 18px;
      height: 18px;
      right: 15px;
    }
  }

  &.toggle_open {
    &:after {
      background: url(#{mixin.set_common_path('toggle_minus_blue.svg')}) no-repeat center center;
      background-size: 100% auto;
      transform: rotate(180deg);
      transition-duration: 0.3s;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: .7;
  }
}

.accordion_body {
  display: none;
  padding-top: 34px;

  p {
    padding-bottom: 0 !important;

    +p {
      margin-top: 40px;

    }
  }
}

&.faq {
  .accordion_title {
    padding-left: 60px;

    @include mixin.tabletP {
      padding-left: 35px;
    }

    &::before {
      color: map_get(variables.$COLOR, darkblue);
      content: "Q";
      display: inline-block;
      font-family: variables.$FONT_ALPHA;
      font-size: 28px;
      font-weight: 700;
      line-height: normal;
      top: 0;
      left: 0;
    }
  }

  .accordion_body {
    padding-left: 60px;

    @include mixin.tabletP {
      padding-left: 35px;
    }
  }
}
}

.flow-unit {
  margin-bottom: 50px;
  counter-reset: flow_num;

  .card-outer {
    padding-bottom: 40px;
    position: relative;

    @include mixin.tabletP {
      padding-top: 15px;
    }

    +.card-outer {
      margin-top: 10px;

    }

    &::after {
      border-style: solid;
      border-width: 34px 34px 0 34px;
      border-color: #f2e3d1 transparent transparent transparent;
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  .card-header {
    background-color: variables.$COLOR_MAIN;
    border-radius: 12px 12px 0 0;
    padding: 0;

    .flow-ttl {
      color: white;
      font-size: calc(16px + 4 * (100vw - 320px) / 1600);
      font-weight: 700;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 0 15px;
      line-height: normal;
      padding-right: 15px;

      &::before {
        border-right: 1px white solid;
        counter-increment: flow_num;
        content: "0" counter(flow_num);
        display: inline-block;
        font-family: 'Nunito', sans-serif;
        font-size: calc(18px + 14 * (100vw - 320px) / 1600);
        font-weight: 700;
        padding: 15px;
        padding-right: 10px;
        white-space: nowrap;

        @include mixin.tabletP {
          padding: 15px;
        }
      }
    }
  }

  .flow-card {
    background-color: map_get(variables.$COLOR, pale-gray);
    border: 1px map_get(variables.$COLOR, pearl-orange) solid;
    border-radius: 0 0 12px 12px;
    gap: 0 30px;
    flex-direction: row;
    line-height: normal;
    padding: 25px 35px;
    position: relative;

    @include mixin.tabletP {
      flex-direction: column;
      padding: 20px;
    }

    .card-img {
      border-radius: 12px;
      width: 30%;

      @include mixin.tabletP {
        margin-top: 10px;
        width: 100%;
      }

      &.p-ratio {

        &::before {
          padding-top: 70.6896%;
        }
      }

      .object-fit-img {
        height: 100% !important;
      }
    }

    .card-body {
      width: 70%;

      @include mixin.tabletP {
        width: 100%;
      }
    }

    .card-text {
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;
    }

    &.col1 {
      padding: 40px 30px;

      @include mixin.tabletP {
        padding: 20px;
        padding-top: 30px;
      }

      .card-body {
        width: 100%;
      }
    }
  }
}

.list_1 {
  box-sizing: border-box;
  align-items: stretch;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 50px;
  width: 100%;

  @include mixin.tabletP {
    gap: 40px 0
  }

  >li {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: calc(100% / 2 - 20px);

    @include mixin.tabletP {
      width: 100%;
    }

    >a {
      background-color: map_get(variables.$COLOR, paleblue);
      border-left: 6px variables.$COLOR_MAIN solid;
      color: variables.$COLOR_MAIN;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;
      padding: 14px 20px;
      padding-right: 30px;
      text-decoration: none;
      min-height: 60px;
      position: relative;

      @include mixin.tabletP {
        min-height: auto;
      }

      &::before {
        background: url(#{mixin.set_common_path('icon_arrow_greem_sm.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    >ul {
      padding: 0 20px;
      margin-top: 20px;

      >li {
        display: block;
        border: none;

        +li {
          margin-top: 15px;
        }

        a {
          color: variables.$COLOR_TEXT;
          font-weight: 700;
          text-decoration: none;
        }

        >.acod-panel {
          margin-top: 20px;
          padding-left: 20px;

          li {

            +li {
              margin-top: 15px;
            }

            >a {
              font-weight: 400;
              padding: 0;
              padding-left: 10px;
              position: relative;

              &::before {
                background: none;
                color: black;
                content: "-";
                display: inline-block;
                text-decoration: none;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.accordion {
  position: relative;

  >a {
    padding-right: 20px;
  }

  .ac-toggle {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    right: 0;

    &::before,
    &::after {
      background: variables.$COLOR_MAIN;
      content: "";
      display: block;
      width: 14px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 0.3s;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &::after {
      transform: translate(-50%, -50%);
    }

    &.is-open {
      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        opacity: 0;
      }
    }

    span {
      display: block;
      padding-left: 20px;
    }
  }

  .acod-panel {
    display: none;
  }
}

@include mixin.tabletP {
  .modContents {

    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 10px;
    }

    .size-full {
      margin: 0 auto 10px;
      width: 100%;
    }

    .scroll_wrap {
      margin-bottom: 30px;
      width: 100%;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;

      &:before {
        content: "→スクロールできます。"
      }
    }

    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }

    .tbl_responsive {
      border: none;

      th,
      td {
        display: block;
        width: 100% !important;
      }

      td {
        border-top: none;
      }
    }
  }
}