@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* form
------------------------------------------------------------*/
form {
  width: 100%;
  max-width: 100%;
}

textarea,
input,
input[type="password"],
input[type="email"],
input[type="text"],
input[type="tel"],
select {
  background-color: map_get(variables.$COLOR, gray);
  box-sizing: border-box;
  border: none;
  color: variables.$COLOR_TEXT;
  display: inline-block;
  font-size: 16px;
  padding: 10px 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  &::placeholder {
    color: #E5E5E5;
    font-size: 16px;
    font-weight: 700;
  }

  &.error {
    background-color: map_get(variables.$COLOR, gray);
    border: solid 2px map_get(variables.$COLOR, red);
  }
}

input,
input[type="password"],
input[type="email"],
input[type="text"]:not(.search__input),
input[type="tel"],
select {
  height: 72px;

  @include mixin.tabletP {
    height: 52px;
  }
}

input:not(.search__input):-webkit-input-placeholder,
input:not(.search__input)::-ms-input-placeholde {
  color: #E5E5E5;
  font-size: 16px;
  font-weight: 700;
}

label {
  vertical-align: middle;
  margin-bottom: 0;
}

/* radio
-------------*/
input[type="radio"] {
  /* @include accessibilityHidden; */

  &+.label {
    cursor: pointer;
    display: inline-block;
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding: 0 30px;
    padding-left: 26px;
    width: 100%;
    max-width: 100%;
    position: relative;

    @include mixin.tabletP {
      font-size: 14px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: .3s ease;
    }

    &::before {
      background: white;
      border: 1px solid #666;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      background-color: white;
      border-radius: 100%;
      height: 8px;
      width: 8px;
      left: 6px;
      top: 50%;
      transform: translateY(-50%) scale(0.1);
      opacity: 0;
    }

    &:hover {
      &::before {
        background: #fff;
      }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background-color: #1A52D3;
        border-color: #1A52D3;
      }

      &::after {
        opacity: 1;
        transform: translateY(-50%) scale(1);
      }
    }
  }
}

/* checkbox
-------------*/
input[type="checkbox"] {
  /*  @include accessibilityHidden; */

  &+.label {
    cursor: pointer;
    display: inline-block;
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding: 0 30px;
    padding-left: 26px;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: .3s ease;
    }

    &::before {
      background: white;
      border: 1px solid #666;
      height: 20px;
      width: 20px;
      left: 0;
      top: 3px;
    }

    &::after {
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      height: 8px;
      width: 11px;
      left: 5px;
      top: 7px;
      transform: rotate(-45deg) scale(0.3);
      opacity: 0;
    }

    &:hover {
      &::before {
        background: #fff;
      }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background: #1A52D3;
        border-color: #1A52D3;
      }

      &::after {
        opacity: 1;
        transform: rotate(-45deg) scale(1);
      }
    }
  }
}

/* ==[ contact form7 ]==================================== */
/* ラジオボタン・チェックボックス共通 */
input[type="checkbox"],
input[type="radio"] {
  height: auto;
  width: auto;
  opacity: 0;
  position: absolute;
}

/* チェックボックス */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {

    &.wpcf7-acceptance,
    &.wpcf7-checkbox {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
      }

      span.wpcf7-list-item {
        position: relative;
      }

      .wpcf7-list-item-label {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding-left: 30px;
        width: 100%;
        max-width: 100%;
        position: relative;

        @include mixin.tabletP {
          font-size: 15px;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          transition: .3s ease;
        }

        &::before {
          background: white;
          border: 2px solid #cfcfcf;
          border-radius: 100%;
          height: 22px;
          width: 22px;
          left: 0;
          top: 3px;
        }

        &::after {
          border-bottom: 2px solid white;
          border-left: 2px solid white;
          height: 8px;
          width: 11px;
          left: 5px;
          top: 8px;
          transform: rotate(-45deg) scale(0.3);
          opacity: 0;
        }

        &:hover {
          &::before {
            background: #fff;
          }
        }
      }

      input[type="checkbox"] {
        &:checked {
          +.wpcf7-list-item-label {
            &::before {
              background: variables.$COLOR_MAIN;
              border-color: variables.$COLOR_MAIN;
            }

            &::after {
              opacity: 1;
              transform: rotate(-45deg) scale(1);
            }
          }
        }
      }
    }
  }
}

/* ラジオボタン */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {
    &.wpcf7-radio {
      gap: 0 58px;
      display: flex;

      span.wpcf7-list-item {
        position: relative;

        &.first {
          margin: 0 !important
        }
      }

      input[type="radio"] {
        position: absolute;
        opacity: 0;
      }

      .wpcf7-list-item-label {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding-left: 30px;
        width: 100%;
        max-width: 100%;
        position: relative;

        @include mixin.tabletP {
          font-size: 15px;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          transition: .3s ease;
        }

        &::before {
          background: white;
          border: 2px solid #cfcfcf;
          border-radius: 100%;
          height: 22px;
          width: 22px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &::after {
          background-color: variables.$COLOR_MAIN;
          border-radius: 100%;
          height: 12px;
          width: 12px;
          left: 5px;
          top: 50%;
          transform: translateY(-50%) scale(0.1);
          opacity: 0;
        }

        &:hover {
          &::before {
            background: #fff;
          }
        }
      }

      input[type="radio"] {
        &:checked {
          +.wpcf7-list-item-label {
            &::before {
              border-color: variables.$COLOR_MAIN;
            }

            &::after {
              opacity: 1;
              transform: translateY(-50%) scale(1);
            }
          }
        }
      }
    }
  }
}

.wpcf7-form-control-wrap {
  &[data-name="your-name"] {
    display: flex;
    align-items: center;
    gap: 0 20px;

    @include mixin.tabletP {
      gap: 0 10px;
    }

    &::after {
      content: "様";
      display: inline-block;
    }
  }
}

/* ajax loader */
.wpcf7 {
  .ajax-loader {
    display: block !important;
    text-align: center;
    margin: 0 auto !important;
  }
}

.wpcf7-not-valid-tip {
  color: map_get(variables.$COLOR, red);
  font-size: 12px;
}


/* ==[ MW WP Form ]==================================== */
/* ラジオボタン */
.mwform-radio-field {
  margin: 0 .5em;

  input[type=radio] {
    appearance: none;
    display: none;

    +span {
      display: inline-block;
      cursor: pointer;
      padding-left: 20px;
      position: relative;

      &:before {
        border: 1px #333 solid;
        border-radius: 100%;
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    &:checked {
      +span {
        &:before {
          background-color: #333;
          border-radius: 100%;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }
    }
  }
}

/* チェックボタン */
.mwform-radio-field {
  margin: 0 .5em;

  input[type=checkbox] {
    appearance: none;
    display: none;

    +span {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;

      &:before {
        border: 1px #333 solid;
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    &:checked {
      +span {
        &::before {
          background: url("../img/common/icon_check.svg") white no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

/* 必須badge */
.required {
  display: flex;
  align-items: center;
  gap: 0 10px;

  &::after {
    background-color: map_get(variables.$COLOR, orange);
    color: white;
    content: "必須";
    display: block;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 2px;
    text-align: center;
    width: 40px;

    @include mixin.tabletP {
      font-size: 12px;
    }
  }
}