@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

#footer {}
.gFooter {
  background-color: white;
  padding: 80px 0 30px;

  @include mixin.tabletP {
    padding: 50px 0 30px;
  }

  a {
    text-decoration: none;
  }

  &__group1 {
    @include mixin.tabletP {
      text-align: center;
    }

    .companyBlock {
      display: flex;
      flex-direction: column;
      gap: 5px 0;
      margin-top: 20px;
    }

    .addressList,
    .companyList {
      display: flex;

      @include mixin.tabletP {
        justify-content: center;
      }

      li {
        font-size: calc(14px + 4 * (100vw - 320px) / 1600);
      }
    }

    .addressList {
      gap: 0 10px;
      width: 100%;

      @include mixin.tabletP {
        gap: 0;
        flex-direction: column;
      }

      .gMap {
        color: map_get(variables.$COLOR, blue);
        font-size: 14px;
        padding-left: 25px;
        position: relative;

        &::before {
          background: url(#{mixin.set_common_path('icon_mapping_blue.svg')}) no-repeat center center;
          background-size: 100% auto;
          content: "";
          display: block;
          width: 20px;
          height: 18px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    .companyList {
      gap: 0 20px;
      width: 100%;

      @include mixin.tabletP {
        gap: 0 10px;
      }

      li {
        &::before {
          display: inline-block;
        }

        &.tel {
          &::before {
            content: "TEL:";
          }
        }

        &.fax {
          &::before {
            content: "FAX:";
          }
        }
      }

      a {
        color: variables.$COLOR_TEXT;
      }
    }
  }

  &__group2 {
    .modBtnBlock {
      display: flex;
      gap: 0 20px;

      @include mixin.tabletP {
        justify-content: center;
        flex-direction: column;
        gap: 10px 0;
      }

      .modBtn {
        margin: 0;

        @include mixin.tabletP {
          margin: 0 auto;
        }
      }
    }
  }

  .modBtnBlock {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .gFooterNavBlock {
    @include mixin.tabletP {
      display: none;
    }
  }

  .license {
    font-size: 14px;
    margin-top: 5px;
  }

  &__nav {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 0;
    width: 100%;
    max-width: 100%;

    .nav-item {
      width: calc(100% / 3);
    }

    .nav-link {
      color: variables.$COLOR_TEXT;
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      padding: 0;

      &:hover {
        color: variables.$COLOR_MAIN;
        opacity: 1;
      }
    }
  }

  &__bnr {

    img {
      width: auto;
    }
  }

  &__privacy {
    margin-top: 20px;
    display: none;
    text-align: center;

    @include mixin.tabletP {
      display: block;
    }

    &--link {
      color: variables.$COLOR_TEXT;
      font-weight: 700;
    }
  }

  &__copyright {
    font-size: calc(11px + 3 * (100vw - 320px) / 1600);
    margin-top: 80px;

    @include mixin.tabletP {
      text-align: center;
      margin-top: 20px;
    }
  }
}
