@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#header {}
.gHeader {
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .16);
  transition: 0.3s ease;

  &__search {
    display: none;

    @include mixin.tabletP {
      display: block;
    }

    img {
      height: 60px;
    }
  }

  &__toggle {
    @include mixin.tabletP {
      display: flex;
      height: 60px;
      overflow: hidden;
    }
  }

  .navbar {
    padding: 0;

    &__inner {
      padding: 20px 15px;

      @include mixin.tabletP {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .navbar-brand {
    padding: 0;

    @include mixin.tabletP {
      margin-left: 15px;
    }

    img {
      width: calc(337 / 1920 * 100vw);
      max-width: 337px;
      min-width: 168px;
    }
  }

  .collapse {

    @include mixin.tabletP {
      height: 100vh;
      padding: 0;
    }

    &__inner {
      display:flex;
      flex-direction: column-reverse;
      padding: 16px 15px;

      @include mixin.tabletP {
        display: block;
        padding: 0;
        padding-bottom: 30vh;
        max-height: 100vh !important;
      }
    }
  }

  [class^="gHeader__group"] {

    .navbar-nav {
      align-items: center;
    }

    .nav-link {
      color: variables.$COLOR_TEXT;
      font-size: calc(14px + 2 * (100vw - 320px) / 1600);
      padding: 0;
      text-decoration: none;
    }
  }

  &__group1 {

    @include mixin.tabletP {
      margin-top: 30px;
    }

    .navbar-nav {
      gap: 0 30px;
      justify-content: flex-end;

      @include mixin.tabletL {
        gap: 0;
        justify-content: flex-start;
      }
    }

    .nav-item {
      @include mixin.tabletP {
        padding: 16px;
      }
    }

    .nav-link {
      font-weight: 700;

      &:hover {
        color: variables.$COLOR_MAIN;
        opacity: 1;
      }

      &.contact__link {

        &:hover {
          opacity: .7;
        }

        img {
          width: calc(213 / 1920 * 100vw);
          max-width: 213px;
          min-width: 140px;
        }
      }
    }
  }

  &__group2 {
    margin-bottom: 20px;

    .navbar-nav {
      gap: 0 20px;
      justify-content: flex-end;

      @include mixin.tabletP {
        border-top: 1px variables.$COLOR_MAIN solid;
        border-bottom: 1px variables.$COLOR_MAIN solid;
        gap: 0 20px;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        position: relative;
      }

      .nav-item {
        @include mixin.tabletP {
          padding: 20px 0;
        }
      }
    }

    .nav-link {

      &.-fav,
      &.-history {
        display: flex;
        align-items: center;
        gap: 10px;

        &::before {
          content: "";
          display: block;
          width: 24px;
          height: 21px;
        }
      }

      &.-fav {
        &::before {
          background: url(#{mixin.set_common_path('icon_fav_black_sm.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.-history {
        &::before {
          background: url(#{mixin.set_common_path('icon_history_black_sm.svg')}) no-repeat center center;
          background-size: 100% auto;
        }
      }
    }
  }


  /* ナビトグル */
  .navbar-toggler {
    background-color: variables.$COLOR_MAIN;
    border-radius: 0;
    border: none;
    padding: 5px;
    width: 62px;
    height: 60px;
    box-sizing: border-box;
    position: relative;
    top: auto;
    right: auto;
    z-index: 9999;

    &::after {
      color: white;
      content: "メニュー";
      display: block;
      font-size: 12px;
      text-align: center;
      text-decoration: none;
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 0;
    }
  }

  .navbar-toggler-icon {
    background-image: none;
    background-color: white;
    width: 20px;
    height: 3px;
    display: block;
    position: absolute;
    transition: ease .5s;
    left: 50%;
    transform: translate(-50%, 0);

    &:nth-of-type(1) {
      top: 15px;
    }

    &:nth-of-type(2) {
      top: 22px;
    }

    &:nth-of-type(3) {
      top: 29px;
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon {

      &:nth-of-type(1) {
        top: 22px;
        transform: translateX(-50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        transform:scaleX(0);
      }

      &:nth-of-type(3) {
        top: 22px;
        transform: translateX(-50%) rotate(135deg);
      }
    }
  }

  /* ドロップダウンメニュー */
  .dropdown-menu {
    border: none;
    border-radius: 0;
    padding: 0;
    padding-left: 30px;
    max-width: 100%;
    min-width: auto;
    transition: all 0.5s ease;
    transform: translate(0, 0);
    left: 0;

    @media screen and (min-width: 768px) {
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
      padding-left: 0;
      text-align: center;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 2px);
      min-width: 20rem;
    }
  }

  .dropdown-item {
    color: variables.$COLOR_TEXT !important;
    font-size: calc(14px + 2 * (100vw - 375px) / 1545);
    padding: 10px;

    &:hover {
      background-color: transparent;
      opacity: .7;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    @media screen and (max-width: 767px) {
      padding: 5px;
      width: 100%;
    }
  }

  /* ドロップダウントグル */
  .dropdown-toggle {
    border: 1px #222 solid;
    display: inline-block;
    width: 22px;
    height: 22px;

    &[data-toggle="dropdown"] {
      display: inline-block;
      position: absolute;
      top: 20px;
      right: 15px;

      @media screen and (min-width: 768px) {
        display: none;
      }

      &::before,
      &::after {
        background: #222;
        content: "";
        display: block;
        width: 16px;
        height: 2px;
        position: absolute;
        top: 8px;
        right: 2px;
        transition: .3s ease;
        transform: rotate(0deg);

      }
    }

    &[aria-expanded="false"] {
      &::after {
        transform: rotate(90deg);
      }
    }

    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .spContainer {
    display: none;

    @include mixin.tabletP {
      display: block;
      padding: 0 15px;
    }

    .modFlex {
      display: flex;
      flex-direction: row;
      gap: 0 10px;
      margin-top: 80px;

      @include mixin.tabletP {
        margin-top: 50px;
      }
    }
  }
}