// color
$COLOR: (
  paleblue: #E5ECF4,
  blue: #1A52D3,
	gray: #F5F5F5,
  darkgray: #989898,
  paleorange: #FEF7EA,
  orange: #EC6C00,
	red: #d10000,
) !default;

$COLOR_TEXT: #333;
$COLOR_MAIN: #004891;

// base
$BASE: (
	font-size: 16px,
	line-height: 1.5,
) !default;

// brake-point
$BRAKE_POINT: (
  sp: 600px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_ALPHA: 'Oswald', Arial, Helvetica, sans-serif;
$FONT_ALPHA_CURSIVE: 'Allura', cursive;
$FONT_ALPHA_SPARTAN: 'Spartan', Arial, Helvetica, sans-serif;
$FONT_MINCHO: "Ryumin Medium KL", '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
$FONT_NUMBER: 'Barlow', sans-serif;
