@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* パンくずリスト */
.myBlockBreadcrumb {
  background-color: map_get(variables.$COLOR, gray);

  .breadcrumb__list {
    padding: 16px 15px;
    margin: 0 auto;
    width: 1200px;
    max-width: 100%;
  }
}

.breadcrumb {
	&.pankuzu {
		background-color: transparent;
		padding: 0;
		margin-bottom: 0;
		align-items: center;
	}

  .breadcrumb-item {

    a {
      color: variables.$COLOR_TEXT;

      &:hover {
        text-decoration: underline;
      }
    }

    +.breadcrumb-item {
      padding: 0;
      position: relative;

      &::before {
        content: "/";
        display: inline-block;
        padding: 0 10px;
      }
    }

    &.active {
      color: variables.$COLOR_TEXT;
      text-decoration: none;
    }
  }
}



/* ページネーション */
.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
	margin: 0 auto 50px;
  width: 100%;

	@include mixin.tabletP {
		gap: 0 5px;
	}

	a,
	span {
    background-color: #F7F7F7;
    color: variables.$COLOR_TEXT;
		display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: variables.$FONT_ALPHA;
    font-size: calc(16px + 8 * (100vw - 320px) / 1600);
		font-weight: 700;
		height: 46px;
		width: 46px;
		text-align: center;
    text-decoration: none;
		padding: 0;

		@include mixin.tabletP {
			height: 30px;
			width: 30px;
		}
	}
	a {
		&:hover {
			background-color: variables.$COLOR_MAIN;
			color: #fff;
			text-decoration: none;
			opacity: .7;
		}

		&.previouspostslink,
		&.nextpostslink {
      font-family: variables.$FONT_BASE;
      font-size: calc(12px + 6 * (100vw - 320px) / 1600);
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: normal;
      padding: 0 20px;
      white-space: nowrap;
      width: auto;
      position: relative;

      @include mixin.tabletP {
        padding: 0 10px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 18px;
        height: 9px;
        position: absolute;
        top: calc(50% - 3px);

        @include mixin.tabletP {
          width: 12px;
          height: 6px;
          top: 50%;
        }
      }

			&:hover {
				color: variables.$COLOR_TEXT;
				background-color: #F7F7F7;
			}
		}

    &.previouspostslink {
      padding-left: 40px;

      @include mixin.tabletP {
        padding-left: 16px;
      }

      &::before {
        background: url("#{mixin.set_common_path('icon_dropdown_blue_sm.svg')}");
        background-size: 100% auto;
        transform: rotate(90deg) translateY(-50%);
        left: 5px;

        @include mixin.tabletP {
          left: 0;
        }
      }
    }

    &.nextpostslink {
      padding-right: 40px;

      @include mixin.tabletP {
        padding-right: 16px;
      }

      &::after {
        background: url("#{mixin.set_common_path('icon_dropdown_blue_sm.svg')}");
        background-size: 100% auto;
        transform: rotate(-90deg) translateY(-50%);
        right: 5px;

        @include mixin.tabletP {
          right: 0;
        }
      }
    }
	}
	span {
		&.current {
			background-color: variables.$COLOR_MAIN;
			color: white;
		}
	}
}



