@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
modTxt
*/

/*
#styleguide
.modTxtLead

```
<p class="modTxtLead">イースター株式会社が運営するWEB会員サイト（以下：当サイト）の利用については、利用規約に必ず同意頂き、ご利用ください。（利用規約については、事前の通知なく変更できるものとします。将来変更された場合、利用者が変更後に利用する際には変更が適用されるものとし、利用者は変更に同意したものとみなされます。）</p>
```
*/

.-en {
  font-family: variables.$FONT_ALPHA;
  font-weight: 700;
  letter-spacing: .1em;
}

.fc-orange {
  color: map_get(variables.$COLOR, orange);
}

.fc-blue {
  color: map_get(variables.$COLOR, blue);
}

.fc-main {
  color: variables.$COLOR_MAIN;
}

.modTxtLead {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;

  +.modTxtLead {
    margin-top: 60px;

    @include mixin.tabletP {
      margin-top: 30px;
    }
  }

  &.-lg {
    font-size: calc(24px + 20 * (100vw - 320px) / 1600);
    line-height: normal;
  }
}//.modTxtLead

.privacy__link {
  color: #1A52D3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: normal;
}

h1 {
  font-size: calc(30px + 10 * (100vw - 320px) / 1600);
}

h2 {
  font-size: calc(26px + 6 * (100vw - 320px) / 1600);
}

h3 {
  font-size: calc(22px + 6 * (100vw - 320px) / 1600);
}

h4 {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}

h5 {
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
}

h6 {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}

