@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


.modkFormTbl {
  border: none;
  display: table;
  table-layout: fixed;

  th,
  td {
    border: none;
    background-color: white;
  }

  th {
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    font-weight: 700;
    padding-bottom: 10px;
  }

  td {
    display: block;
    padding-bottom: 20px;
    width: 100%;
  }

  .input-wrap {

    +.input-wrap {

      @include mixin.tabletP {
        margin-top: 10px;
      }
    }
  }
}

.modTable {
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;

  th,
  td {
    border-bottom: 1px #C7C7C7 solid;
    padding: 10px;
    vertical-align: top;
  }

  th {
    background-color: map_get(variables.$COLOR, gray);
    font-weight: 700;
    width: 200px;

    @include mixin.sp {
      width: 35%;
    }
  }

  td {
    width: calc(100% - 200px);

    @include mixin.sp {
      width: 65%;
    }
  }
}