@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

a {
  &.card {
    text-decoration: none;
  }
}

.modCard {
  a {
    color: variables.$COLOR_TEXT;
    text-decoration: none;
  }
}

.modBg__main {
  background-color: variables.$COLOR_MAIN;
}

.modRatio {
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.modPriceBlock {
  text-align: right;
}

.price {
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  font-weight: 700;
  letter-spacing: normal;

  &::after {
    content: "円";
    display: inline-block;
    font-size: calc(12px + 6 * (100vw - 320px) / 1600);
    padding-left: 5px;

    @include mixin.tabletP {
      padding-left: 2px;
    }
  }
}

.icon-fav {
  background-image: url(#{mixin.set_common_path('icon_fav_gray_md.svg')});
  background-size: 31px 27px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  width: 31px;
  height: 27px;

  &.is-active {
    background-image: url(#{mixin.set_common_path('icon_fav_orange_md.svg')});
  }
}

.undernegotiation {
  position: relative;

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    background-color: rgba(1, 72, 145, .3);
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &::after {
    border: 1px white solid;
    color: white;
    content: "商談中";
    display: inline-block;
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    padding: 4px 5px 6px;
    text-align: center;
    width: 130px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.update {
  color: map_get(variables.$COLOR, darkgray);
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);

  &::before {
    content: "更新日：";
    display: inline-block;
  }
}

.tag {
  background-color: variables.$COLOR_TEXT;
  color: white;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  padding: 0 5px 1px;
}

.tab-content {
  .tab-pane {
    display: none;
    transition: .5s ease;

    &.active {
      display: block;
    }
  }
}