@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


.bg-paleorange {
  background-color: map_get(variables.$COLOR, paleorange);
}

.modBtnBlock {
  margin-top: 30px;

  nclude mixin.tabletP {
    margin-top: 20px;
  }
}

.modFlex {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 100%;

  &--item {
    width: 100%;
  }

  &.column2 {
    .modFlex__item {
      width: calc(100% / 2);
    }
  }

  &.column3 {
    .modFlex__item {
      width: calc(100% / 3);
    }
  }
}

.modsliderBlock {
  overflow: hidden;
}

.myBlockContact {
  background-color: variables.$COLOR_MAIN;
  color: white;

  .modTitle {

    &__en {
      &--sub {
        color: white;
      }
    }
  }

  .contxt {
    text-align: center;
    margin-bottom: 30px;

    @include mixin.tabletP {
      text-align: left;
    }
  }

  .contactList {
    box-sizing: border-box;
    display: flex;
    gap: 0 18px;

    @include mixin.tabletP {
      flex-wrap: wrap;
      gap: 15px 0;
    }

    &__item {
      width: calc(100% / 2 - 8px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.modBlockForm {
  border: none;
  margin-bottom: 50px;
}

.mocCardBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100%;

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 184px);

    @include mixin.tabletP {
      margin-top: 20px;
      width: 100%;
    }
  }

  &__link {
    color: variables.$COLOR_TEXT;
    text-decoration: none;

    &:hover {
      color: variables.$COLOR_TEXT;
    }
  }

  &__card {
    border: 1px #C7C7C7 solid;
    padding: 20px 15px;
    width: 100%;
    max-width: 100%;

    @include mixin.tabletP {
      padding: 15px 10px;
    }

    &--header {
      display: flex;
      align-items: center;
      gap: 0 10px;
      margin-bottom: 20px;

      @include mixin.tabletP {
        margin-bottom: 10px;
      }
    }

    &--ttl {
      display: flex;
      align-items: center;
      gap: 0 10px;

      .location {
        font-size: calc(18px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
      }
    }

    &--inner {
      display: flex;
      gap: 0 30px;
      margin-bottom: 16px;

      @include mixin.tabletL {
        gap: 0 20px;
      }

      @include mixin.tabletP {
        gap: 10px 0;
        flex-wrap: wrap;
      }
    }

    &--img {
      width: 360px;

      @include mixin.tabletL {
        width: 280px;
      }

      @include mixin.tabletP {
        width: 100%;
      }

      .modImgBlock {
        position: relative;

        &.modRatio {
          &::before {
            padding-top: 68.8705%;
          }
        }
      }
    }

    &--body {
      width: calc(100% - 390px);

      @include mixin.tabletL {
        width: calc(100% - 300px);
      }

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &--txt {

      &.-top {
        margin-bottom: 10px;

        @include mixin.tabletP {
          margin-bottom: 5px;
        }
      }

      &.-bottom {
        margin-top: 40px;

        @include mixin.tabletP {
          margin-top: 15px;
        }
      }
    }

    &--footer {

      .modFlexList {
        flex-wrap: wrap;
        gap: 15px 0;
        width: 100%;

        @include mixin.tabletL {
          gap: 10px 0;
        }

        &__item {
          display: flex;
          width: calc(100% / 3);

          @include mixin.tabletL {
            width: calc(100% / 2);
          }

          @include mixin.tabletP {
            width: 100%;
          }
        }

        &__ttl {
          font-weight: 700;
          width: 35%;
        }

        &__txt {
          width: 65%;
        }
      }
    }
  }

}
