@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
modTitle
*/

/*
.modTitle

```
<div class="modTitle">
  <h2 class="modTitle__en">Title</h2>
    <p class="modTitle__en--sub">sub title</p>
  <p class="modTitle__ja">タイトルが入ります</p>
</div>< !-- /.modTitle -->
```
*/

.modTitle {
  text-align: center;
  margin-bottom: 30px;

  @include mixin.tabletP {
    margin-bottom: 20px;
  }

  &__jp {
    font-weight: 700;
    line-height: normal;
    letter-spacing: .05em;
  }

  &__en {
    font-family: variables.$FONT_ALPHA;
    font-size: calc(42px + 8 * (100vw - 320px) / 1600);
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1;

    &--sub {
      color: map_get(variables.$COLOR, orange);
      font-family: variables.$FONT_ALPHA_CURSIVE;
      font-size: 20px;
      letter-spacing: .1em;

      @include mixin.tabletP {
        font-size: 16px;
      }
    }
  }


}//.modTitle

/*
.heading

```
<p class="heading">
  <span class="deco -en">TYPE</span>
</p>
```
*/

.heading {
  text-align: center;
  margin-bottom: 10px;

  .deco {
    line-height: normal;
    padding: 0 65px;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 17px;
      height: 22px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      background: url(#{mixin.set_common_path('icon_slash_left.svg')}) no-repeat center center;
      background-size: 100% auto;
      left: 0;
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_slash_right.svg')}) no-repeat center center;
      background-size: 100% auto;
      right: 0;
    }
  }
}
